import React, { useContext, Component } from "react";
import Icon from "@material-ui/core/Icon";
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import { Button } from "@material-ui/core";

import { FyneContext } from "../../FyneContext";

export const Intro2 = ({...props}) => {// = class Intro1 extends Component {
  const config = useContext(FyneContext);
  console.log('ElectricianLanding Intro1', {props,config});

  let state = {};
  //render() {
    return (
      <section className="section section-intro1 section-intro2" id="intro2"
      style={{background: 'url('+config.APP_BASE+'assets/images/home-bg-black.png) center center/cover no-repeat'}}>
        <div className="container">
          <div className="text-center">
            <div className="section-intro1__subtitle mb-24 text-secondary">
              A friendly and reliable service
            </div>
            <h1 className="section-intro1__title">
              Fyne Electricians
            </h1>
            <p className="intro1__description">
              Get in touch today
            </p>
            <div>
              <Button
                className="bg-white open-booking-dialog"
                variant="contained"
                size="large"
                aria-label="Buy"
              >
                <OfflineBoltIcon className="mr-16">flight_takeoff</OfflineBoltIcon>
                Book Now
              </Button>
            </div>
          </div>
          <div className="section-intro2__product text-center">
            <img src="https://res.cloudinary.com/fynewo/image/upload/v1589278399/landing/electrician.jpg" alt="" />
          </div>
        </div>
      </section>
    );
  //}
}

export default Intro2;
