import React, { useState, useEffect, useContext } from "react";
import { scrollTo } from "../Utils";
import Intro2 from "./sections/Intro2";
import Portfolio2 from "./sections/Portfolio2";
import Testimonial2 from "./sections/Testimonial2";
import Services2 from "./sections/Services2";
import CallToAction1 from "./sections/CallToAction1";
import Pricing1 from "./sections/Pricing1";
import Contact1 from "./sections/Contact1";
import Footer1 from "./sections/Footer1";
import TopBar2 from "./sections/TopBar2";
import { FyneContext } from "../fyneworks";
import { useFetch } from "use-http";
import { Button } from '@material-ui/core'

export const PlumberLanding = ({
  ...props
}) => {
  
  useEffect(()=>{
    scrollTo('root');
  }, [])

  const config = useContext(FyneContext);
  console.log('PlumberLanding', {props,config});

  const [ result, setResult ] = useState({})
  const { post, get, loading, data } = useFetch()


  return (
    <div className="landing">
      <TopBar2 />
      <Intro2 />
      <Button className="testing-class" onClick={
        async event=>{
            await post('/event/test', {test_class: event.target.className})
            setResult(data);
          }
        }>
        {
          !!loading ? 'Working' : 'Test Me'
        }
      </Button>
      <Portfolio2 />
      <Testimonial2 />
      <Services2 />
      <CallToAction1 bg="./assets/images/home-bg-black.png"/>
      <Pricing1 />
      <Contact1 />
      <Footer1 />
    </div>
  );
}

export default PlumberLanding;
